<template>
  <div class="editor">
    <div ref="toolbar" class="toolbar"></div>
    <div ref="editor" class="text"></div>
  </div>
</template>

<script>
import E from 'wangeditor'
import { apiUpload } from '@/api/demand'
export default {
  name: 'EditorBar',
  data() {
    return {
      info: null,
      editor: '',
      isChange: false
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    isClear: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isClear(val) {
      console.log(val)
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear()
        this.info = null
      }
    },
    value() {
      if (!this.isChange) {
        this.editor.txt.html(this.value)
      }
      this.isChange = false
    }
  },
  mounted() {
    this.seteditor()
  },
  methods: {
    seteditor() {
      this.editor = new E(this.$refs.toolbar, this.$refs.editor)
      console.log(this.editor)
      this.editor.config.uploadImgHeaders = {
        token: sessionStorage.getItem('token')
      } // 自定义 header
      this.editor.config.uploadFileName = 'image' // 后端接受上传文件的参数名
      this.editor.config.uploadImgMaxSize = 12 * 1024 * 1024 // 将图片大小限制为 12M
      this.editor.config.uploadImgMaxLength = 6 // 限制一次最多上传 3 张图片
      this.editor.config.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间
      // 4.自定义控制上传过程--上传到阿里云
      this.editor.config.customUploadImg = async (files, insert) => {
        const fileData = new FormData()
        fileData.append('file', files[0])
        // console.log(files)
        const { data: res } = await apiUpload(fileData)
        if (res.resultCode == 200) {
          // console.log(res)
          // console.log(res);
          insert(res.data)
        }
      }
      this.editor.config.showFullScreen = true
      // 配置菜单

      this.editor.config.menus = [
        'bold', // 粗体
        'underline', // 下划线
        'link', // 插入链接
        'quote', // 引用
        'image', // 插入图片
        'justify' // 对齐方
      ]

      this.editor.config.uploadImgHooks = {
        fail: (xhr, editor, result) => {
          // 插入图片失败回调
          // console.log(xhr, editor, result)
        },
        success: (xhr, editor, result) => {
          // 图片上传成功回调
          //
          // let imgUrl = result.data;
          // insertImg(imgUrl)
          // console.log(xhr, editor, result)
        },
        timeout: (xhr, editor) => {
          // 网络超时的回调
          // console.log(xhr, editor)
        },
        error: (xhr, editor) => {
          // console.log(editor)
          // 图片上传错误的回调
        },
        customInsert: (insertImg, result, editor) => {
          var url = result.data
          insertImg(url) //将内容插入到富文本中
        }
      }

      this.editor.config.onchange = (html) => {
        this.isChange = true
        this.info = html // 绑定当前逐渐地值
        this.$emit('change', this.info) // 将内容同步到父组件中
      }

      // 创建富文本编辑器
      this.editor.create()
    }
  }
}
</script>

<style scoped lang="less">
.editor {
  width: 100%;
  height: 100%;
  .text {
    border: 1px solid #ccc;
    min-height: 434px;
    max-height: 434px;
  }
}
.toolbar {
  border: 1px solid #ccc;
  margin-bottom: -1px;
}

::v-deep .w-e-toolbar {
  position: static;
  z-index: 999;
}
.w-e-text-container {
  position: static;
  z-index: 999 !important;
}

::v-deep  .w-e-text {
  max-height: 434px;
  
}
</style>
